export const SHOW_LOADER = 'SHOW_LOADER'
export const LOGIN_BY_TOKEN = 'LOGIN_BY_TOKEN'
export interface configState {
  visibleLoader: boolean
  loginByToken: boolean
}

interface AddShowLoader {
  type: typeof SHOW_LOADER
  payload: boolean
}
interface AddLoginByToken {
  type: typeof LOGIN_BY_TOKEN
  payload: boolean
}

export type ConfigActionsTypes = AddShowLoader | AddLoginByToken
