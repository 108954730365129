import {
  SHOW_LOADER,
  ConfigActionsTypes,
  configState,
  LOGIN_BY_TOKEN,
} from '../types/configActionsTypes'

const initialState: configState = {
  visibleLoader: false,
  loginByToken: false,
}

export const ConfigReducer = (
  state = initialState,
  action: ConfigActionsTypes,
): configState => {
  switch (action.type) {
    case SHOW_LOADER: {
      return { ...state, visibleLoader: action.payload }
    }
    case LOGIN_BY_TOKEN: {
      return { ...state, loginByToken: action.payload }
    }
    default:
      return state
  }
}
