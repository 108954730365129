import { ConsultaITPTurismoModel } from 'src/models/ConsultaITPTurismoModel'
import {
  ADD_CALCULADORA_CONSULTA_ITPMOTOS,
  ADD_CALCULADORA_CONSULTA_ITPTURISMO,
  CalculoTypesActions,
  calculoState,
} from '../types/calculoActionsTypes'
import { ConsultaITPMotosModel } from 'src/models/ConsultaITPMotosModel'

const calculosState: calculoState = {
  ITPTurismo: new ConsultaITPTurismoModel(),
  ITPMotos: new ConsultaITPMotosModel(),
}

export const CalculoReducer = (
  state = calculosState,
  action: CalculoTypesActions,
): calculoState => {
  switch (action.type) {
    case ADD_CALCULADORA_CONSULTA_ITPTURISMO: {
      return { ...state, ITPTurismo: action.payload }
    }
    case ADD_CALCULADORA_CONSULTA_ITPMOTOS: {
      return { ...state, ITPMotos: action.payload }
    }

    default:
      return state
  }
}
