import axiosconfig from 'src/utils/axios/axiosconfig'
import { NotifyType, showToast } from 'src/utils/sharedUtils'

export const postLogin = async (
  user: string,
  password: string,
): Promise<string | null> => {
  const datosInicioSesion = {
    user: user,
    password: password,
  }

  let token = null

  await axiosconfig
    .post('Login', datosInicioSesion)
    .then((response) => {
      token = response.data.token
    })
    .catch((error) => {
      showToast(
        `Error al iniciar sesión ${
          error.message !== undefined ? error.message : ''
        }`,
        NotifyType.error,
        5000,
      )

      token = null
    })

  return token
}

export const getLogin = async (key?: string): Promise<any> => {
  const token = await axiosconfig
    .get(key !== undefined ? `Login?key=${key}` : 'Login')
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      showToast(
        `Error al enviar la consulta ${
          error.message !== undefined ? error.message : ''
        }`,
        NotifyType.error,
        5000,
      )

      return null
    })

  return token.token
}
