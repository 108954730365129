import { ConsultaITPMotosModel } from 'src/models/ConsultaITPMotosModel'
import { MotosModel } from 'src/models/motosModel'
import { ResultadoConsultaModel } from 'src/models/resultadoConsultaModel'
import { UserModel } from 'src/models/userModel'
import axiosconfig from 'src/utils/axios/axiosconfig'
import { NotifyType, showToast } from 'src/utils/sharedUtils'
import { authHeader } from 'src/utils/sharedUtils'

export const postConsultITPMotos = async (
  consulta: ConsultaITPMotosModel,
): Promise<ResultadoConsultaModel> => {
  const resultadoconsulta = await axiosconfig
    .post('Calculos/Moto', consulta)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      showToast(
        `Error al enviar la consulta: ${
          error.message !== undefined ? error.message : ''
        }`,
        NotifyType.error,
        5000,
      )

      return null
    })

  return resultadoconsulta
}

export const getMotos = async (
  clasificacion: number,
  tipovehiculo: number,
): Promise<Array<MotosModel>> => {
  const motos = await axiosconfig
    .get(`Motos?clasificacion=${clasificacion}&idTipoVehiculo=${tipovehiculo}`)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      showToast(
        `Error al obetener las cilindradas ${
          error.message !== undefined ? error.message : ''
        }`,
        NotifyType.error,
        5000,
      )

      return null
    })

  return motos
}
