import axios, { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'

export interface AxiosInstanceError {
  error: {
    code: number | null
    data: any
    details: any
    message: string
    valdiationErrors: any
  }
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || '',
})

axiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError<AxiosInstanceError>) => {
    if (error.response!.status === 401) {
      //Redireccionamos vista login
      localStorage.removeItem('user')
      window.location.reload()
    }

    const responseError =
      (error.response && error.response.data) ?? 'Something went wrong'
    return Promise.reject(responseError)
  },
)

//insertamos token en el header de axios, de esta manera no tendremos que estar dandole el toke siempre. Esto se hace en login
export const setAuthToken = (token: any) => {
  if (token) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
  } else delete axiosInstance.defaults.headers.common['Authorization']
}

export default axiosInstance
