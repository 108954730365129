import { ConsultaITPTurismoModel } from 'src/models/ConsultaITPTurismoModel'
import { ResultadoConsultaModel } from 'src/models/resultadoConsultaModel'
import axiosconfig from 'src/utils/axios/axiosconfig'
import { NotifyType, showToast } from 'src/utils/sharedUtils'

export const postConsultITPTurismo = async (
  consulta: ConsultaITPTurismoModel,
): Promise<ResultadoConsultaModel> => {
  const resultadoconsulta = await axiosconfig
    .post('Calculos/Coche', consulta)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      showToast(
        `Error al enviar la consulta: ${
          error.message !== undefined ? error.message : ''
        }`,
        NotifyType.error,
        5000,
      )

      return null
    })

  return resultadoconsulta
}
