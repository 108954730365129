import { combineReducers } from 'redux'
import { CalculoReducer } from './CalculoRedux/reducers/calculoReducer'
import { UserReducer } from './users/reducers/userReducer'
import { ConfigReducer } from './configRedux/reducers/configReducer'

const rootReducer = combineReducers({
  calculo: CalculoReducer,
  user: UserReducer,
  config: ConfigReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
