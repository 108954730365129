import { datosDeTransferencia } from 'src/sections/DatosConsultaSection/datosConsultaSection'

export function StringToBoolean(valor: string) {
  return valor === 'true' ? true : false
}

export function getTimeLogIn() {
  var d = new Date()
  var horaInicioSesion =
    ('0' + d.getDate()).slice(-2) +
    '-' +
    ('0' + (d.getMonth() + 1)).slice(-2) +
    '-' +
    d.getFullYear() +
    ' ' +
    ('0' + d.getHours()).slice(-2) +
    ':' +
    ('0' + d.getMinutes()).slice(-2)
  return horaInicioSesion
}

export function formatoFecha(formdate: Date): string {
  let año = formdate.getFullYear()
  let mes =
    formdate.getMonth() + 1 > 9
      ? formdate.getMonth() + 1
      : `0${formdate.getMonth() + 1}`
  let dia =
    formdate.getDate() > 9 ? formdate.getDate() : `0${formdate.getDate()}`

  return `${año}-${mes}-${dia}`
}

export function formatoParaFecha(formdate: string): Date {
  let fecha = Date.parse(formdate)

  return new Date(fecha)
}

export function fechaFormatoConsulta(fecha: string): String {
  let datosFechas = fecha.split('-')

  return `${datosFechas[2]}/${datosFechas[1]}/${datosFechas[0]}`
}

export function esFechaMatriculacionSuperiorVenta(
  fechamatriculacion: Date,
  fechaventa: Date,
) {
  return new Promise((resolve) => {
    let resultado = fechamatriculacion <= fechaventa ? true : false

    resolve(resultado)
  })
}

export function checkScreenSize() {
  var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    windowWidth = w.innerWidth || e.clientWidth || g.clientWidth //window width

  return windowWidth > 1024 //returns true for widths larger than 568 pixels
}

export function registrarDatosTransferencia(
  datosConsulta: any,
): datosDeTransferencia[] {
  let añadirRegistro: datosDeTransferencia[] = []
  for (let registro in datosConsulta) {
    if (
      typeof datosConsulta[registro] === 'number' &&
      ['antiguedad'].indexOf(registro) === -1
    ) {
      switch (registro) {
        case 'valorNuevo':
          añadirRegistro.push({
            label: 'Valor nuevo',
            cantidad: Intl.NumberFormat('de-DE', {
              style: 'currency',
              currency: 'EUR',
            }).format(datosConsulta[registro]),
          })
          break

        case 'porcentajeDepreciacion':
          añadirRegistro.push({
            label: 'Porcentaje depreciación',
            cantidad: `${datosConsulta[registro] * 100}%`,
          })
          break
        case 'baseImponible':
          añadirRegistro.push({
            label: 'Base imponible',
            cantidad: Intl.NumberFormat('de-DE', {
              style: 'currency',
              currency: 'EUR',
            }).format(datosConsulta[registro]),
          })
          break

        case 'cuotaITP':
          añadirRegistro.push({
            label: 'Cuota itp',
            cantidad: Intl.NumberFormat('de-DE', {
              style: 'currency',
              currency: 'EUR',
            }).format(datosConsulta[registro]),
          })
          añadirRegistro.push({
            label: 'Itp aplicado',
            cantidad: datosConsulta['itpAplicado'],
          })
          break

        case 'tasasDGT':
          añadirRegistro.push({
            label: 'Tasa dgt',
            cantidad: Intl.NumberFormat('de-DE', {
              style: 'currency',
              currency: 'EUR',
            }).format(datosConsulta[registro]),
          })
          break

        case 'honorarios':
          añadirRegistro.push({
            label: 'Honorarios,derechos colegiales,mensajería.....',
            cantidad: Intl.NumberFormat('de-DE', {
              style: 'currency',
              currency: 'EUR',
            }).format(datosConsulta[registro]),
          })
          break
        case 'total':
          añadirRegistro.push({
            label: 'Total',
            cantidad: Intl.NumberFormat('de-DE', {
              style: 'currency',
              currency: 'EUR',
            }).format(datosConsulta[registro]),
          })
          break

        default:
          break
      }
    }
  }

  return añadirRegistro
}
