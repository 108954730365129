import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('ModeloModel')
export class ModeloModel {
  @JsonProperty('id', Number)
  id: number = 0
  @JsonProperty('marca', String)
  marca: string = ''
  @JsonProperty('modelo', String)
  modelo: string = ''
  @JsonProperty('inicioPeriodoComercial', Number)
  inicioPeriodoComercial: number = 0
  @JsonProperty('finPeriodoComercial', Number)
  finPeriodoComercial: number = 0
  @JsonProperty('cc', Number)
  cc: number = 0
  @JsonProperty('cilindros', Number)
  cilindros: number = 0
  @JsonProperty('combustible', String)
  combustible: string = ''
  @JsonProperty('potenciaKWh', String)
  potenciaKWh: string = ''
  @JsonProperty('cvf', Number)
  cvf: number = 0
  @JsonProperty('cv', Number)
  cv: number = 0
  @JsonProperty('valor', Number)
  valor: number = 0
}
