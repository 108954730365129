import { CombustibleModel } from 'src/models/combustibleModel'
import { ComunidadAutonomaModel } from 'src/models/comunidadAutonomaModel'
import { MarcasModel } from 'src/models/marcasModel'
import { ModeloModel } from 'src/models/modeloModel'
import { OpcionesAdicionalesModel } from 'src/models/opcionesAdicionalesModel'
import { TipoVehiculoModel } from 'src/models/tipoVehiculoModels'
import { UserModel } from 'src/models/userModel'
import axiosInstance from 'src/utils/axios/axiosconfig'
import { NotifyType, authHeader, showToast } from 'src/utils/sharedUtils'

export const getComunidadesAutonomas =
  async (): Promise<Array<ComunidadAutonomaModel> | null> => {
    const comunidadesAutonomas = await axiosInstance
      .get('ComunidadesAutonomas')
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        showToast(
          `Error al obtener las comunidades autónomas ${
            error.message !== undefined ? error.message : ''
          }`,
          NotifyType.error,
          5000,
        )

        return null
      })

    return comunidadesAutonomas
  }

export const getTipoVehiculos = async (
  id: number,
): Promise<Array<TipoVehiculoModel> | null> => {
  const tiposVehiculos = await axiosInstance
    .get(`TiposVehiculos?clasificacion=${id}`)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      showToast(
        `Error al obtener los tipos de vehiculos  ${
          error.message !== undefined ? error.message : ''
        }`,
        NotifyType.error,
        5000,
      )

      return null
    })

  return tiposVehiculos
}

export const getMarcas = async (
  id: number,
  fechaprimeramatriculacion: string,
): Promise<Array<MarcasModel> | null> => {
  const marcas = await axiosInstance
    .get(
      `Marcas?fechaPrimeraMatriculacion=${fechaprimeramatriculacion}&idTipoVehiculo=${id}`,
    )
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      showToast(
        `Error al obtener las marcas ${
          error.message !== undefined ? error.message : ''
        }`,
        NotifyType.error,
        5000,
      )

      return null
    })

  return marcas
}

export const getCombustible = async (
  id: number,
  fechaPrimeraMatriculacion: string,
  marca: number,
): Promise<Array<CombustibleModel> | null> => {
  const combustibles = await axiosInstance
    .get(
      `Combustibles?idMarca=${marca}&fecha=${fechaPrimeraMatriculacion}&idTipoVehiculo=${id}`,
    )
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      showToast(
        `Error al obtener los combustibles  ${
          error.message !== undefined ? error.message : ''
        }`,
        NotifyType.error,
        5000,
      )

      return null
    })

  return combustibles
}
export const getModelos = async (
  siglas: string,
  id: number,
  fechaPrimeraMatriculacion: string,
  marca: number,
): Promise<Array<ModeloModel> | null> => {
  const modelos = await axiosInstance
    .get(
      `Modelos?siglasCombustible=${siglas}&idMarca=${marca}&fechaPrimeraMatriculacion=${fechaPrimeraMatriculacion}&idTipoVehiculo=${id}`,
    )
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      showToast(
        `Error al obtener los modelos  ${
          error.message !== undefined ? error.message : ''
        }`,
        NotifyType.error,
        5000,
      )

      return null
    })

  return modelos
}

export const getOpcionesAdicionales = async (
  user: UserModel,
  comunidad: number,
  tipovehiculo: number,
): Promise<OpcionesAdicionalesModel> => {
  const opcioneadicionales = await axiosInstance
    .get(
      `OpcionesAdicionales?idComunidad=${comunidad}&idTipoVehiculo=${tipovehiculo}`,
    )
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      showToast(
        `Error al recibir las opciones adicionales  ${
          error.message !== undefined ? error.message : ''
        }`,
        NotifyType.error,
        5000,
      )

      return null
    })

  return opcioneadicionales
}
