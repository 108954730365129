import { LoadPanel } from 'devextreme-react'
import React, { createContext, useContext } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { ParamsUrlModel } from 'src/models/paramsurlModel'
import { RootState } from 'src/redux/combineReducers'

const ParamsContext = createContext(new ParamsUrlModel())

export default function IndexCalculadora({
  children,
}: {
  children: React.ReactNode
}) {
  const [queryParameters] = useSearchParams()

  const paramsUrl = new ParamsUrlModel(
    queryParameters.get('key'),
    queryParameters.get('theme'),
    queryParameters.get('idGestoria'),
    queryParameters.get('vienedelportal'),
  )

  const { visibleLoader } = useSelector((state: RootState) => state.config)

  return (
    <>
      <ParamsContext.Provider value={paramsUrl}>
        {children}
        <LoadPanel
          shadingColor='RGBA(234, 234, 234, 0.5)'
          visible={visibleLoader}
        />
      </ParamsContext.Provider>
    </>
  )
}

export const ParamsUrl = () => {
  return useContext(ParamsContext)
}
