import { ConsultaITPTurismoModel } from 'src/models/ConsultaITPTurismoModel'
import {
  ADD_CALCULADORA_CONSULTA_ITPMOTOS,
  ADD_CALCULADORA_CONSULTA_ITPTURISMO,
  CalculoTypesActions,
} from '../types/calculoActionsTypes'
import { ConsultaITPMotosModel } from 'src/models/ConsultaITPMotosModel'

export const AddConsultaITPTurismo = (
  consultaturismo: ConsultaITPTurismoModel,
): CalculoTypesActions => ({
  type: ADD_CALCULADORA_CONSULTA_ITPTURISMO,
  payload: consultaturismo,
})

export const AddConsultaITPMotos = (
  consultamotos: ConsultaITPMotosModel,
): CalculoTypesActions => ({
  type: ADD_CALCULADORA_CONSULTA_ITPMOTOS,
  payload: consultamotos,
})
