import { Box, Button, Form } from 'devextreme-react'
import { Item } from 'devextreme-react/box'
import { GroupItem, RequiredRule, SimpleItem } from 'devextreme-react/form'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Cookies from 'src/components/Cookies/Cookies'
import {
  actualizarCookieConsent,
  getCookieConsent,
} from 'src/components/Utils/CookiesUtils/userCookie'
import { addUser } from 'src/redux/users/actions/userAction'
import { getLogin, postLogin } from 'src/services/loginServices'
import { StringToBoolean } from 'src/utils/calculadoraUtils'
import { NotifyType, showToast } from 'src/utils/sharedUtils'
import { RootState } from 'src/redux/combineReducers'
import {
  AddLoginByToken,
  addShowLoader,
} from 'src/redux/configRedux/actions/configActions'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { setAuthToken } from 'src/utils/axios/axiosconfig'
import { ParamsUrl } from '../indexcalculadora'
import axios from 'axios'

class inicioSesion {
  Nombre: string = ''
  Password: string = ''
}

export default function Login() {
  const [iniciosesion, setInicioSesion] = useState(new inicioSesion())
  const navegate = useNavigate()
  const paramsUrlState = ParamsUrl()
  const dispatch = useDispatch()
  const [verLogin, setVerLogin] = useState(false)

  useEffect(() => {
    if (paramsUrlState.theme.length > 0) {
      if (window.localStorage.getItem('dx-theme') !== paramsUrlState.theme) {
        window.localStorage.setItem('dx-theme', paramsUrlState.theme)
        window.location.reload()
      }
    }
    if (localStorage.getItem('user') !== null) {
      let token = JSON.parse(localStorage.getItem('user')!).Token
      dispatch(addUser(JSON.parse(localStorage.getItem('user')!)))
      setAuthToken(token)
      navegate('/calculadora/index')
    }
    if (paramsUrlState.key.length > 0) {
      obtenerTokenByKey(paramsUrlState.key)
    } else {
      setVerLogin(true)
    }
  }, [paramsUrlState])

  async function obtenerTokenByKey(key: string) {
    let usuario = await getLogin(key)
    let nombregestoria = ''
    if (paramsUrlState.vienedelportal === 'false') {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL_CORE}/Companies/${paramsUrlState.idgestoria}`,
          {
            headers: { Authorization: `Bearer ${usuario}` },
          },
        )
        .then((data) => {
          nombregestoria = data.data.Name
        })
    }

    dispatch(addUser({ Token: usuario, UserName: nombregestoria }))
    dispatch(AddLoginByToken(true))
    setAuthToken(usuario)
    setVerLogin(false)
    navegate('/calculadora/index')
  }

  async function loguearUsuario() {
    let esLogueado = await postLogin(iniciosesion.Nombre, iniciosesion.Password)
    if (esLogueado !== null) {
      localStorage.setItem(
        'user',
        JSON.stringify({ Token: esLogueado, UserName: iniciosesion.Nombre }),
      )
      dispatch(addUser({ Token: esLogueado, UserName: iniciosesion.Nombre }))
      dispatch(addShowLoader(false))
      setAuthToken(esLogueado)
      navegate('/calculadora/index')
    } else {
      dispatch(addShowLoader(false))
      showToast(`Usuario o/y Contraseña Incorrectos`, NotifyType.error, 5000)
    }
  }
  const [usecookiesconsent, setCookiesConsent] = useState(getCookieConsent())

  function actualizarCookiesUser(estado: string) {
    setCookiesConsent('true')

    if (StringToBoolean(estado)) {
      actualizarCookieConsent()
    }
  }

  async function sesionInvitado() {
    let user = await getLogin()
    dispatch(addUser({ Token: user, UserName: 'invitado' }))
    setAuthToken(user)
    localStorage.setItem(
      'user',
      JSON.stringify({ Token: user, UserName: 'invitado' }),
    )
    navegate('/calculadora/index')
  }
  return (
    <>
      {verLogin && (
        <div>
          <Box
            direction='row'
            align='center'
            crossAlign='center'
            width={'96%'}
            height={900}
          >
            <Item ratio={0} baseSize={300}>
              <Box
                direction='row'
                width={330}
                height={580}
                className='loginform'
              >
                <Item ratio={0} baseSize={25}></Item>
                <Item>
                  <Box direction='col' width={285} height={500}>
                    <Item baseSize={140}>
                      <img src={require('../../imgs/newlogo.png')} alt='' />
                    </Item>
                    <Item ratio={0} baseSize={350}>
                      <Form
                        formData={iniciosesion}
                        labelLocation='top'
                        validationGroup='customerData'
                        width={'auto'}
                      >
                        <GroupItem colCount={1}>
                          <SimpleItem
                            dataField={'Nombre'}
                            label={{ text: 'Nombre Usuario' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              stylingMode: 'filled',
                              mode: 'text',
                              hint: 'Nombre De Usuario',
                              value: iniciosesion.Nombre,
                              onValueChanged: (e: any) => {
                                if (e.event) {
                                  if (e.value != null) {
                                    setInicioSesion({
                                      ...iniciosesion,
                                      Nombre: e.value,
                                    })
                                  }
                                }
                              },
                            }}
                          >
                            <RequiredRule
                              message={'el nombre de usuario es obligatorio'}
                            ></RequiredRule>
                          </SimpleItem>
                        </GroupItem>
                        <GroupItem colCount={1}>
                          <SimpleItem
                            dataField={'Password'}
                            label={{ text: 'Contraseña' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              stylingMode: 'filled',
                              mode: 'password',
                              hint: 'Contraseña',
                              value: iniciosesion.Password,
                              onValueChanged: (e: any) => {
                                if (e.event) {
                                  if (e.value != null) {
                                    setInicioSesion({
                                      ...iniciosesion,
                                      Password: e.value,
                                    })
                                  }
                                }
                              },
                            }}
                          >
                            <RequiredRule
                              message={'el nombre de usuario es obligatorio'}
                            ></RequiredRule>
                          </SimpleItem>
                        </GroupItem>

                        <GroupItem colCount={1}>
                          <Box
                            direction='row'
                            align='center'
                            crossAlign='center'
                            height={30}
                          >
                            <Item ratio={0} baseSize={160}>
                              <Button
                                text={'Iniciar Sesión'}
                                type='default'
                                validationGroup={'customerData'}
                                useSubmitBehavior={true}
                                onClick={(e: any) => {
                                  e.event.preventDefault()
                                  let result = e.validationGroup.validate()
                                  if (result.isValid) {
                                    dispatch(addShowLoader(true))
                                    loguearUsuario()
                                  } else {
                                    showToast(
                                      `Usuario y Contraseña Deben Estar Rellenos`,
                                      NotifyType.error,
                                      5000,
                                    )
                                  }
                                }}
                              />
                            </Item>
                          </Box>
                        </GroupItem>
                        <GroupItem colCount={1}>
                          <Box
                            direction='row'
                            align='center'
                            crossAlign='center'
                            height={60}
                          >
                            <Item ratio={0} baseSize={180}>
                              <Button
                                text={'Acceso Invitado'}
                                type='default'
                                onClick={(e: any) => {
                                  e.event.preventDefault()
                                  sesionInvitado()
                                }}
                              />
                            </Item>
                          </Box>
                        </GroupItem>
                      </Form>
                    </Item>
                    <Item>
                      <Box
                        direction='row'
                        width={'auto'}
                        align='center'
                        crossAlign='center'
                      >
                        <Item baseSize={35}></Item>

                        <Item ratio={0} baseSize={'auto'}>
                          <a
                            href='https://www.elgestor.com/aviso-legal/'
                            className='links'
                          >
                            Aviso Legal
                          </a>
                        </Item>

                        <Item ratio={0} baseSize={'auto'}>
                          <a
                            href='https://www.elgestor.com/politica-privacidad/'
                            className='links'
                          >
                            Política Privacidad
                          </a>
                        </Item>

                        <Item ratio={0} baseSize={'auto'}>
                          <a
                            href='https://www.elgestor.com/politica-corporativa/'
                            className='links'
                          >
                            {' '}
                            Política Corporativa
                          </a>
                        </Item>
                      </Box>
                    </Item>
                  </Box>
                </Item>
              </Box>
            </Item>
          </Box>
          {!StringToBoolean(usecookiesconsent!) && (
            <Cookies actualizarestado={actualizarCookiesUser} />
          )}
        </div>
      )}
    </>
  )
}
