import React from 'react'
import ReactDOM from 'react-dom'
import themes from 'devextreme/ui/themes'
import { loadMessages, locale } from 'devextreme/localization'
import enMessages from 'devextreme/localization/messages/en.json'
import esMessages from 'devextreme/localization/messages/es.json'
import App from './app'
import { unregister } from './serviceWorker'
import 'devextreme/dist/js/dx.all.js'

localStorage.removeItem('APP_VERSION')

let currentLenguage = window.localStorage.getItem('lenguage') || 'es-ES'
loadMessages(esMessages)
loadMessages(enMessages)
locale(currentLenguage)

themes.current(window.localStorage.getItem('dx-theme') || 'generic.light')
themes.initialized(() => {
  ReactDOM.render(<App />, document.getElementById('root'))
})

unregister()
