export enum Calificaciones {
  'Turismos,Todoterrenos y Autocaravanas' = 1,
  'Ciclomotores y motocicletas de combustión' = 2,
  'Ciclomotores y motocicletas eléctricos' = 3,
  'Quads' = 4,
  'Otros-Desactivados' = 99,
}

export const clasificacionVehiculosTurismo = [
  {
    Id: 1,
    Name: 'Turismos,Todoterrenos y Autocaravanas',
  },
]

export const clasificacionVehiculoMotocicleta = [
  {
    Id: 2,
    Name: 'Ciclomotores y motocicletas de combustión',
  },
  {
    Id: 3,
    Name: 'Ciclomotores y motocicletas eléctricos',
  },
  {
    Id: 4,
    Name: 'Quads',
  },
]
