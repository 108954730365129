import { Box, Button, DataGrid, Form, Popup, ScrollView } from 'devextreme-react'
import { Item } from 'devextreme-react/box'
import { Column } from 'devextreme-react/data-grid'
import { ToolbarItem } from 'devextreme-react/date-box'
import { GroupItem, SimpleItem } from 'devextreme-react/form'
import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/redux/combineReducers'
import { addShowLoader } from 'src/redux/configRedux/actions/configActions'
import { fechaFormatoConsulta, registrarDatosTransferencia } from 'src/utils/calculadoraUtils'
import * as XLSX from 'xlsx'
import jsPDF from 'jspdf'


interface Props {
  datosConsulta: any
  cerrar: Function
}

export interface datosDeTransferencia {
  label: string
  cantidad: number | string
}

export default function DatosConsultaSection({ cerrar, datosConsulta }: Props) {
  const formRef: MutableRefObject<any> = useRef(null)
  const dispatch = useDispatch()
  const loaderState = useSelector(
    (state: RootState) => state.config.visibleLoader,
  )
  const [datostransferencia, setDatosTransferencia] = useState(
    Array<datosDeTransferencia>(),
  )
  function exportarDatos() {
    try {
      let nombreFichero = `resultado calculo.xlsx`
      let datos = [JSON.parse(JSON.stringify(datosConsulta))]

      let datosmapeados = datos.map((dato) => ({
        Modelo: dato.vehiculo,
        Combustible: dato.combustible,
        'Fecha Primera Matriculacion':
          dato.fechaPrimeraMatriculacion !== null
            ? fechaFormatoConsulta(dato.fechaPrimeraMatriculacion)
            : null,
        'Fecha Venta':
          dato.fechaVenta !== null
            ? fechaFormatoConsulta(dato.fechaVenta)
            : null,
        Antiguedad: dato.antiguedad,
        'Comunidad Autonoma': dato.comunidadAutonoma,
        'Valor Nuevo': dato.valorNuevo !== null ? dato.valorNuevo : null,
        'Porcentaje Depreciacion':
          dato.porcentajeDepreciacion !== null
            ? dato.porcentajeDepreciacion
            : null,
        'Base Imponible':
          dato.baseImponible !== null ? Number(dato.baseImponible) : null,
        'Itp Aplicado': dato.itpAplicado,
        'Cuota Itp': dato.cuotaITP !== null ? dato.cuotaITP : null,
        'Tasa DGT': dato.tasasDGT !== null ? dato.tasasDGT : null,
        Honorarios: dato.honorarios !== null ? dato.honorarios : null,
        Total: dato.total !== null ? dato.total : null,
        Observaciones: dato.observaciones,
        'Mensaje Legal': dato.mensajeLegal,
      }))

      let WorkSheet = XLSX.utils.json_to_sheet(datosmapeados)
      WorkSheet['!cols'] = [
        { width: datosConsulta.vehiculo.length },
        {
          width:
            datosConsulta.combustible.length +
            Math.abs(datosConsulta.combustible.length - 'Combustible'.length),
        },
        {
          width:
            datosConsulta.fechaPrimeraMatriculacion !== null
              ? fechaFormatoConsulta(
                  datosConsulta.fechaPrimeraMatriculacion,
                ).toString().length +
                Math.abs(
                  fechaFormatoConsulta(
                    datosConsulta.fechaPrimeraMatriculacion,
                  ).toString().length - 'Fecha Primera Matriculacion'.length,
                )
              : 6 + Math.abs(6 - 'Fecha Primera Matriculacion'.length),
        },
        {
          width:
            datosConsulta.fechaVenta !== null
              ? fechaFormatoConsulta(datosConsulta.fechaVenta).toString()
                  .length +
                Math.abs(
                  fechaFormatoConsulta(datosConsulta.fechaVenta).toString()
                    .length - 'Fecha Venta'.length,
                )
              : 6 + Math.abs(6 - 'Fecha Venta'.length),
        },
        {
          width:
            datosConsulta.antiguedad.toString().length +
            Math.abs(
              datosConsulta.antiguedad.toString().length - 'Antiguedad'.length,
            ),
        },
        {
          width:
            datosConsulta.comunidadAutonoma.length +
            Math.abs(
              datosConsulta.comunidadAutonoma.length -
                'Comunidad Autonoma'.length,
            ) +
            2,
        },
        {
          width:
            datosConsulta.valorNuevo !== null
              ? Intl.NumberFormat('de-DE', {
                  style: 'currency',
                  currency: 'EUR',
                })
                  .format(datosConsulta.valorNuevo)
                  .toString().length +
                Math.abs(
                  Intl.NumberFormat('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                  })
                    .format(datosConsulta.valorNuevo)
                    .toString().length - 'Valor Nuevo'.length,
                )
              : 6 + Math.abs(6 - 'Valor Nuevo'.length),
        },
        {
          width:
            `${datosConsulta.porcentajeDepreciacion}%`.length +
            Math.abs(
              `${datosConsulta.porcentajeDepreciacion}%`.length -
                'Porcentaje Depreciacion'.length,
            ),
        },
        {
          width:
            datosConsulta.baseImponible !== null
              ? Intl.NumberFormat('de-DE', {
                  style: 'currency',
                  currency: 'EUR',
                })
                  .format(datosConsulta.baseImponible)
                  .toString().length +
                Math.abs(
                  Intl.NumberFormat('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                  })
                    .format(datosConsulta.baseImponible)
                    .toString().length - 'Base Imponible'.length,
                )
              : 6 + Math.abs(6 - 'Base Imponible'.length),
        },
        {
          width:
            datosConsulta.itpAplicado.length +
            Math.abs(datosConsulta.itpAplicado.length - 'Itp Aplicado'.length),
        },
        {
          width:
            datosConsulta.cuotaITP !== null
              ? Intl.NumberFormat('de-DE', {
                  style: 'currency',
                  currency: 'EUR',
                })
                  .format(datosConsulta.cuotaITP)
                  .toString().length +
                Math.abs(
                  Intl.NumberFormat('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                  })
                    .format(datosConsulta.cuotaITP)
                    .toString().length - 'Cuota Itp'.length,
                )
              : 6 + Math.abs(6 - 'Cuota Itp'.length),
        },
        {
          width:
            datosConsulta.tasasDGT !== null
              ? Intl.NumberFormat('de-DE', {
                  style: 'currency',
                  currency: 'EUR',
                })
                  .format(datosConsulta.tasasDGT)
                  .toString().length +
                Math.abs(
                  Intl.NumberFormat('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                  })
                    .format(datosConsulta.tasasDGT)
                    .toString().length - 'Tasa DGT'.length,
                ) +
                1
              : 6 + Math.abs(6 - 'Tasa DGT'.length) + 1,
        },
        {
          width:
            datosConsulta.honorarios !== null
              ? Intl.NumberFormat('de-DE', {
                  style: 'currency',
                  currency: 'EUR',
                })
                  .format(datosConsulta.honorarios)
                  .toString().length +
                Math.abs(
                  Intl.NumberFormat('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                  })
                    .format(datosConsulta.honorarios)
                    .toString().length - 'Honorarios'.length,
                )
              : 6 + Math.abs(6 - 'Honorarios'.length),
        },
        {
          width:
            datosConsulta.total !== null
              ? Intl.NumberFormat('de-DE', {
                  style: 'currency',
                  currency: 'EUR',
                })
                  .format(datosConsulta.total)
                  .toString().length +
                Math.abs(
                  Intl.NumberFormat('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                  })
                    .format(datosConsulta.total)
                    .toString().length - 'Total'.length,
                )
              : 6 + Math.abs(6 - 'Total'.length),
        },
        {
          width:
            datosConsulta.observaciones.length +
            Math.abs(
              datosConsulta.observaciones.length - 'Observaciones'.length,
            ),
        },
        {
          width:
            datosConsulta.mensajeLegal.length +
            Math.abs(
              datosConsulta.mensajeLegal.length - 'Mensaje Legal'.length,
            ),
        },
      ]
      let WorkBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(WorkBook, WorkSheet, 'Datos Calculo')
      XLSX.writeFile(WorkBook, nombreFichero)
    } catch (e) {
      alert('no es posible exportar los datos ' + e)
    }
  }

  useEffect(() => {
    if (loaderState) {
      dispatch(addShowLoader(false))
    }

    document.addEventListener('keydown', function (e) {
      if (e.key === 'Escape') {
        cerrar()
      }
    })

    if (datostransferencia.length === 0) {
      setDatosTransferencia(registrarDatosTransferencia(datosConsulta))
    }

    return () => {
      document.removeEventListener('keydown', function (e) {
        if (e.key === 'Escape') {
          cerrar()
        }
      })
    }
  })
  async function printPdf(){
    const transferenciaPdf = (document.getElementById('datosConsultaSection') as HTMLElement).cloneNode(true) as HTMLElement;
    (transferenciaPdf.querySelector('#exportarSolicitudBtn') as HTMLElement).style.display = 'none';
    (transferenciaPdf.querySelector('#generarSolicitudBtn') as HTMLElement).style.display = 'none';
    Array.from(transferenciaPdf.getElementsByClassName('dx-texteditor-input')).forEach(
      (element: any) => {
        element.classList.add('printDatosVehiculo');
        element.classList.add('dx-texteditor-input-auto-resize');

        if (element.type === 'textarea') {
          let newWords:any = [];

          // Para el elemento 'modelo', añade un salto de línea después de cada 12 caracteres
          if (element.name === 'modelo') {
            for (let i = 0; i < element.value.length; i += 18) {
              let segment = element.value.slice(i, i + 18);
              newWords.push(segment);
              if (i + 18 < element.value.length) {
                newWords.push('\n');
                element.rows++; // Incrementar element.rows cuando se añade un salto de línea
              }
            }
          }
          // Para los elementos 'observaciones' y 'mensajeLegal', añade un salto de línea después de cada 18 caracteres
          else if (element.name === 'observaciones' || element.name === 'mensajeLegal') {
            for (let i = 0; i < element.value.length; i += 40) {
              let segment = element.value.slice(i, i + 40);
              newWords.push(segment);
              if (i + 40 < element.value.length) {
                newWords.push('\n');
                element.rows++; // Incrementar element.rows cuando se añade un salto de línea
              }
            }
          }
          // Para todos los demás elementos, añade un salto de línea después de cada 18 caracteres
          else {
            for (let i = 0; i < element.value.length; i += 18) {
              let segment = element.value.slice(i, i + 18);
              newWords.push(segment);
              if (i + 18 < element.value.length) {
                newWords.push('\n');
                element.rows++; // Incrementar element.rows cuando se añade un salto de línea
              }
            }
          }
          // Une las palabras de nuevo en una cadena de texto
          element.value = newWords.join(' ');
        }
      }
    );

    if (transferenciaPdf) {
      let doc = new jsPDF({
        format: 'a4',
        unit: 'mm',
        orientation: 'portrait',
      });
      doc.html(transferenciaPdf, {
        callback: function(pdf) {
          let pdfBlob = pdf.output('blob');
          window.parent.postMessage({ type: 'pdfGenerated', pdfBlob }, '*');
        },
        x: 10,
        y: 10,
        width: 190,
        windowWidth: 650,
      });
    }
  }

  return (
    <Popup
      visible={true}
      showCloseButton={false}
      height='75%'
      width='90%'
      title={'Resultado cálculo'}
    >
      <ToolbarItem
        widget='dxButton'
        location='after'
        options={{
          icon: 'close',
          onClick: () => {
            setDatosTransferencia([])
            cerrar()
          },
        }}
      />
      <ScrollView>
        <React.Fragment>
          <Box
            direction='row'
            align='center'
            crossAlign='center'
            width={'100%'}
            id="datosConsultaSection"
          >
            <Item ratio={0} baseSize={'90%'}>
              <Form
                ref={formRef}
                readOnly={true}
                labelLocation='top'
                formData={datosConsulta}
                width={'auto'}
              >
                <GroupItem caption={'Datos vehículo'}>
                  <GroupItem colCount={3}>
                    <SimpleItem
                      dataField={'vehiculo'}
                      label={{ text: 'Modelo' }}
                      editorType={'dxTextArea'}
                      editorOptions={{
                        stylingMode: 'filled',
                        mode: 'text',
                        hint: 'modelo vehículo',
                      }}
                    ></SimpleItem>
                    <SimpleItem
                      dataField={'combustible'}
                      label={{ text: 'Combustible' }}
                      editorType={'dxTextBox'}
                      editorOptions={{
                        stylingMode: 'filled',
                        mode: 'text',
                        hint: 'combustible',
                      }}
                    ></SimpleItem>
                    <SimpleItem
                      label={{ text: 'Fecha primera matriculación' }}
                      editorType={'dxTextBox'}
                      editorOptions={{
                        stylingMode: 'filled',
                        mode: 'text',
                        value:
                          datosConsulta.fechaPrimeraMatriculacion !== null
                            ? fechaFormatoConsulta(
                                datosConsulta.fechaPrimeraMatriculacion,
                              )
                            : null,
                        hint: 'fecha primera matriculación',
                      }}
                    ></SimpleItem>
                  </GroupItem>
                  <GroupItem colCount={3}>
                    <SimpleItem
                      label={{ text: 'Fecha venta' }}
                      editorType={'dxTextBox'}
                      editorOptions={{
                        stylingMode: 'filled',
                        mode: 'text',
                        value:
                          datosConsulta.fechaVenta !== null
                            ? fechaFormatoConsulta(datosConsulta.fechaVenta)
                            : null,
                        hint: 'fecha venta',
                      }}
                    ></SimpleItem>
                    <SimpleItem
                      dataField={'antiguedad'}
                      cssClass={'numericos'}
                      label={{ text: 'Antiguedad' }}
                      editorType={'dxTextBox'}
                      editorOptions={{
                        stylingMode: 'filled',
                        mode: 'text',
                        hint: 'antiguedad',
                      }}
                    ></SimpleItem>
                    <SimpleItem
                      dataField={'comunidadAutonoma'}
                      label={{ text: 'Comunidad autónoma' }}
                      editorType={'dxTextBox'}
                      editorOptions={{
                        stylingMode: 'filled',
                        mode: 'text',
                        hint: 'comunidad autónoma',
                      }}
                    ></SimpleItem>
                  </GroupItem>
                </GroupItem>

                <GroupItem colCount={1}></GroupItem>
              </Form>
              <Box
                direction='row'
                align='start'
                crossAlign='start'
                width={'auto'}
              >
                <Item ratio={0} baseSize={'100%'}>
                  <Form width={'auto'}>
                    <GroupItem caption={`Datos transferencia`}></GroupItem>
                  </Form>
                </Item>
              </Box>
              <Box
                direction='row'
                align='center'
                crossAlign='center'
                width={'auto'}
              >
                <Item ratio={0} baseSize={'85%'}>
                  <DataGrid
                    showBorders={true}
                    showColumnHeaders={false}
                    dataSource={datostransferencia}
                    showRowLines
                    allowColumnReordering
                    allowColumnResizing
                    cacheEnabled
                    columnAutoWidth
                    repaintChangesOnly
                    rowAlternationEnabled
                  >
                    <Column
                      alignment='left'
                      dataField={'label'}
                      width={'60%'}
                      cellRender={(data) => {
                        if (data.key.label === 'Total') {
                          return <b>{data.key.label}</b>
                        } else {
                          return data.key.label
                        }
                      }}
                    />
                    <Column
                      alignment='right'
                      dataField={'cantidad'}
                      width={'30%'}
                      cellRender={(data) => {
                        if (data.key.label === 'Total') {
                          return <b>{data.key.cantidad}</b>
                        } else {
                          return data.key.cantidad
                        }
                      }}
                    />
                  </DataGrid>
                </Item>
              </Box>
              <Box
                height={150}
                direction='row'
                align='center'
                crossAlign='center'
                width={'100%'}
              >
                <Item ratio={0} baseSize={'100%'}>
                  <Form
                    ref={formRef}
                    readOnly={true}
                    labelLocation='top'
                    formData={datosConsulta}
                    width={'auto'}
                  >
                    <GroupItem colCount={2}>
                      {datosConsulta.observaciones !== undefined &&
                        datosConsulta.observaciones !== null &&
                        datosConsulta.observaciones !== '' && (
                          <SimpleItem
                            dataField={'observaciones'}
                            label={{ text: 'Observaciones' }}
                            editorType={'dxTextArea'}
                            editorOptions={{
                              stylingMode: 'filled',
                              mode: 'text',
                              hint: 'observaciones',
                            }}
                          ></SimpleItem>
                        )}

                      <SimpleItem
                        dataField={'mensajeLegal'}
                        label={{ text: 'Mensaje legal' }}
                        editorType={'dxTextArea'}
                        editorOptions={{
                          stylingMode: 'filled',
                          mode: 'text',
                          hint: 'mensaje legal',
                        }}
                      ></SimpleItem>
                    </GroupItem>
                  </Form>
                </Item>
              </Box>
              <Box align='end' crossAlign='end' width={'auto'} style={{marginBottom: '-5px'}}>
                <Item ratio={0} baseSize={'auto'}>
                  <Button
                    id={'exportarSolicitudBtn'}
                    text={'Exportar'}
                    type='default'
                    onClick={async (e: any) => {
                      exportarDatos()
                    }}
                    style={{ marginRight: '20px' }}
                  />
                </Item>
                <Item ratio={0} baseSize={'auto'}>
                  <Button
                    id={'generarSolicitudBtn'}
                    text={'Generar solicitud'}
                    type={'default'}
                    onClick={async (e: any) => {
                      printPdf();
                      window.parent.postMessage('generarSolicitudBtn', '*');
                    }}
                  />
                </Item>
              </Box>
            </Item>
          </Box>
        </React.Fragment>
      </ScrollView>
    </Popup>
  )
}
