import { RouteObject } from 'react-router-dom'
import CalculadoraPage from './pages/calculadoraPage'
import Login from './pages/login/login'
import React from 'react'

export const routes: RouteObject[] = [
  { path: '/', element: <Login /> },

  {
    element: <CalculadoraPage />,
    path: '/calculadora/index',
  },
]
