import React from 'react'
import './styles/dx-styles.scss'
import { useScreenSizeClass } from './utils/media-query'
import { BrowserRouter, useRoutes } from 'react-router-dom'
import { Provider, useSelector } from 'react-redux'
import { routes } from './app-routes'
import store from './redux/store'
import { QueryClient, QueryClientProvider } from 'react-query'
import { LoadPanel } from 'devextreme-react'
import { RootState } from './redux/combineReducers'
import Login from './pages/login/login'
import IndexCalculadora from './pages/indexcalculadora'

function Init() {
  const content = useRoutes(routes)
  return <IndexCalculadora>{content}</IndexCalculadora>
}

export default function App() {
  const queryClient = new QueryClient()
  const screenSizeClass = useScreenSizeClass()

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Provider store={store}>
          <div className={`${screenSizeClass}`}>
            <Init />
          </div>
        </Provider>
      </BrowserRouter>
    </QueryClientProvider>
  )
}
