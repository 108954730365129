import { Box, LoadPanel, RadioGroup } from 'devextreme-react'
import { Item } from 'devextreme-react/box'
import Form, { GroupItem, SimpleItem } from 'devextreme-react/form'

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Header from 'src/layout/header/Header'
import { RootState } from 'src/redux/combineReducers'
import FormularioTurismoSection from 'src/sections/FormularioTurismoSection/formularioTurismoSection'
import {
  getCombustible,
  getComunidadesAutonomas,
  getMarcas,
  getModelos,
  getOpcionesAdicionales,
  getTipoVehiculos,
} from 'src/services/calculadoraServices'
import { TipoVehiculoModel } from 'src/models/tipoVehiculoModels'
import usePrevious from 'use-previous'
import { formatoFecha, formatoParaFecha } from 'src/utils/calculadoraUtils'
import footerFormularioSection from 'src/sections/FooterFormularioSection/footerFormularioSection'
import {
  AddConsultaITPMotos,
  AddConsultaITPTurismo,
} from 'src/redux/CalculoRedux/actions/calculoActions'
import { useNavigate } from 'react-router-dom'
import { postConsultITPTurismo } from 'src/services/consultaITPTurismoService'
import {
  getMotos,
  postConsultITPMotos,
} from 'src/services/consultaITPMotosServices'
import DatosConsultaSection from 'src/sections/DatosConsultaSection/datosConsultaSection'
import FormularioMotocicletaSection from 'src/sections/FormularioMotocicletaSection/formularioMotocicletaSection'
import { ConsultaITPMotosModel } from 'src/models/ConsultaITPMotosModel'
import { ConsultaITPTurismoModel } from 'src/models/ConsultaITPTurismoModel'
import { addShowLoader } from 'src/redux/configRedux/actions/configActions'
import { OpcionesAdicionalesModel } from 'src/models/opcionesAdicionalesModel'
import { showToast } from 'src/utils/sharedUtils'
import { MarcasModel } from 'src/models/marcasModel'
import { CombustibleModel } from 'src/models/combustibleModel'
import { ModeloModel } from 'src/models/modeloModel'
import { ComunidadAutonomaModel } from 'src/models/comunidadAutonomaModel'
import { MotosModel } from 'src/models/motosModel'

class camposSection {
  marca: number = 0
  combustible: string = ''
  modelo: number = 0
  clasificacion: number | null = null
  actividadeseconomicas: boolean = false
  distintivocero: boolean = false
  distintivoeco: boolean = false
  listatiposvehiculos: TipoVehiculoModel[] = []
  listacomunidadesautonomas: ComunidadAutonomaModel[] = []
  listamarcas: MarcasModel[] = []
  listacombustible: CombustibleModel[] = []
  listamodelos: ModeloModel[] = []
  listacilindradas: MotosModel[] = []
}
export default function CalculadoraPage() {
  const navegate = useNavigate()
  const dispatch = useDispatch()
  const [initialCamposSection, setInitialCamposSection] = useState(
    new camposSection(),
  )
  const userState = useSelector((state: RootState) => state.user.usuario)
  const configState = useSelector((state: RootState) => state.config)
  const consultaTurismoState = useSelector(
    (state: RootState) => state.calculo.ITPTurismo,
  )
  const consultaMotosState = useSelector(
    (state: RootState) => state.calculo.ITPMotos,
  )

  const [formularioTurismo, setFormularioTurismo]: any = useState(null)

  const [resultadoConsulta, setResultadoConsulta]: any = useState(null)
  const [mostrarResultado, setMostrarResultado]: any = useState(false)
  const PrevTipoVehiculo = usePrevious(
    initialCamposSection.clasificacion !== null
      ? initialCamposSection.clasificacion === 1
        ? consultaTurismoState.idTipoVehiculo
        : consultaMotosState.idTipoVehiculo
      : null,
  )

  const PrevComunidad = usePrevious(
    initialCamposSection.clasificacion !== null
      ? initialCamposSection.clasificacion === 1
        ? consultaTurismoState.idComunidad
        : consultaMotosState.idComunidad
      : null,
  )

  const tipoFormulario = [
    { id: 0, text: 'Turismo' },
    { id: 1, text: 'Motocicleta' },
  ]

  function mostrarPopupConsulta() {
    setMostrarResultado(!mostrarResultado)
  }

  function actualizarCamposSection(datos: any) {
    setInitialCamposSection(datos)
  }

  async function obtenerOpcionesAdicionales() {
    let datos: OpcionesAdicionalesModel | null
    await getOpcionesAdicionales(
      userState!,
      initialCamposSection.clasificacion === 1
        ? consultaTurismoState.idComunidad!
        : consultaMotosState.idComunidad!,
      initialCamposSection.clasificacion === 1
        ? consultaTurismoState.idTipoVehiculo!
        : consultaMotosState.idTipoVehiculo!,
    ).then((response) => {
      datos = response
    })
    if (initialCamposSection.clasificacion === 1) {
      dispatch(
        AddConsultaITPTurismo({
          ...consultaTurismoState,
          distintivoEco: datos!.distintivoEco ? false : null,
          actividadesEconomicas: datos!.actividadesComerciales ? false : null,
          distintivo0Emisiones: datos!.distintivo0Emisiones ? false : null,
        }),
      )
    } else {
      dispatch(
        AddConsultaITPMotos({
          ...consultaMotosState,
          distintivoEco: datos!.distintivoEco ? false : null,
          actividadesEconomicas: datos!.actividadesComerciales ? false : null,
          distintivo0Emisiones: datos!.distintivo0Emisiones ? false : null,
        }),
      )
    }

    setInitialCamposSection({
      ...initialCamposSection,
      actividadeseconomicas: datos!.actividadesComerciales,
      distintivocero: datos!.distintivo0Emisiones,
      distintivoeco: datos!.distintivoEco,
    })
  }

  async function ObtenerConsulta() {
    dispatch(addShowLoader(true))
    let consulta =
      initialCamposSection.clasificacion === 1
        ? await postConsultITPTurismo(consultaTurismoState)
        : await postConsultITPMotos(consultaMotosState)

    if (consulta !== null) {
      setResultadoConsulta(consulta)

      mostrarPopupConsulta()
    } else {
      dispatch(addShowLoader(false))
    }
  }

  function opcionesAdicionales() {
    if (consultaMotosState.idComunidad !== 0) {
      if (
        consultaMotosState.idComunidad !== PrevComunidad ||
        consultaMotosState.idTipoVehiculo !== PrevTipoVehiculo
      ) {
        obtenerOpcionesAdicionales()
      }
    }
  }

  useEffect(() => {
    if (userState.Token === '' || localStorage.getItem('user') === null) {
      if (!configState.loginByToken) {
        navegate('/')
      }
    }

    if (
      initialCamposSection.clasificacion !== null &&
      initialCamposSection.clasificacion !== 1
    ) {
      opcionesAdicionales()
    }

    if (
      initialCamposSection.clasificacion !== null &&
      initialCamposSection.clasificacion === 1 &&
      consultaTurismoState.idComunidad !== 0
    ) {
      if (
        consultaTurismoState.idComunidad !== PrevComunidad ||
        consultaTurismoState.idTipoVehiculo !== PrevTipoVehiculo
      ) {
        obtenerOpcionesAdicionales()
      }
    }
  }, [
    userState.Token,
    initialCamposSection.clasificacion,
    consultaTurismoState.idComunidad,
    consultaTurismoState.idTipoVehiculo,
    consultaMotosState.idComunidad,
  ])

  return (
    <>
      {userState.Token !== '' && userState.UserName !== '' && <Header />}
      <Box width={'auto'} height={80}>
        <Item ratio={0} baseSize={'auto'}>
          {mostrarResultado && (
            <DatosConsultaSection
              datosConsulta={resultadoConsulta}
              cerrar={mostrarPopupConsulta}
            ></DatosConsultaSection>
          )}
        </Item>
      </Box>

      <Box direction='row' width={'100%'} align='center' crossAlign='center'>
        <Item ratio={0} baseSize={'auto'}>
          <h2>Calculadora transferencias</h2>
        </Item>
      </Box>

      <Box direction='row' align='center' crossAlign='center' width={'100%'}>
        <Item ratio={0} baseSize={'80%'}>
          <Form labelLocation='top' validationGroup='customerData'>
            <GroupItem colCount={2}>
              <Box
                direction='row'
                align='center'
                crossAlign='center'
                width={'auto'}
              >
                <Item ratio={0} baseSize={'auto'}>
                  <RadioGroup
                    items={tipoFormulario}
                    layout='horizontal'
                    valueExpr='id'
                    displayExpr='text'
                    value={
                      formularioTurismo !== null
                        ? formularioTurismo
                          ? 0
                          : 1
                        : null
                    }
                    onValueChanged={(e: any) => {
                      if (e.value === 0) {
                        dispatch(
                          AddConsultaITPTurismo({
                            ...new ConsultaITPTurismoModel(),
                          }),
                        )
                        setFormularioTurismo(true)
                      } else {
                        dispatch(
                          AddConsultaITPMotos(new ConsultaITPMotosModel()),
                        )
                        setFormularioTurismo(false)
                      }
                      setInitialCamposSection(new camposSection())
                    }}
                  />
                </Item>
              </Box>
            </GroupItem>

            {formularioTurismo !== null &&
              formularioTurismo &&
              FormularioTurismoSection({
                turismo: consultaTurismoState,
                datos: initialCamposSection,
                actualizardatos: actualizarCamposSection,
                consulta: ObtenerConsulta,
              })}

            {formularioTurismo !== null &&
              !formularioTurismo &&
              FormularioMotocicletaSection({
                motos: consultaMotosState,
                datos: initialCamposSection,
                actualizardatos: actualizarCamposSection,
                consulta: ObtenerConsulta,
              })}
          </Form>
        </Item>
      </Box>
    </>
  )
}
