import Form, {
  AsyncRule,
  GroupItem,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form'
import DataSource from 'devextreme/data/data_source'
import React from 'react'
import { useDispatch } from 'react-redux'
import { ConsultaITPMotosModel } from 'src/models/ConsultaITPMotosModel'
import { ComunidadAutonomaModel } from 'src/models/comunidadAutonomaModel'
import { MotosModel } from 'src/models/motosModel'
import { TipoVehiculoModel } from 'src/models/tipoVehiculoModels'
import { AddConsultaITPMotos } from 'src/redux/CalculoRedux/actions/calculoActions'
import {
  esFechaMatriculacionSuperiorVenta,
  formatoFecha,
  formatoParaFecha,
} from 'src/utils/calculadoraUtils'
import { clasificacionVehiculoMotocicleta } from 'src/models/clasificacionesEnum'
import footerFormularioSection from '../FooterFormularioSection/footerFormularioSection'
import { MarcasModel } from 'src/models/marcasModel'
import { CombustibleModel } from 'src/models/combustibleModel'
import { ModeloModel } from 'src/models/modeloModel'
import {
  getComunidadesAutonomas,
  getTipoVehiculos,
} from 'src/services/calculadoraServices'
import { getMotos } from 'src/services/consultaITPMotosServices'

interface Props {
  motos: ConsultaITPMotosModel

  datos: {
    marca: number | null
    combustible: string | null
    modelo: number | null
    clasificacion: number | null
    actividadeseconomicas: boolean
    distintivocero: boolean
    distintivoeco: boolean
    listatiposvehiculos: TipoVehiculoModel[]
    listacomunidadesautonomas: ComunidadAutonomaModel[]
    listamarcas: MarcasModel[]
    listacombustible: CombustibleModel[]
    listamodelos: ModeloModel[]
    listacilindradas: MotosModel[]
  }
  actualizardatos: Function
  consulta: Function
}
export default function FormularioMotocicletaSection({
  motos,
  datos,
  actualizardatos,
  consulta,
}: Props) {
  const dispatch = useDispatch()
  const listatiposvehiculos = new DataSource({
    loadMode: 'raw',
    load: async () => {
      await getTipoVehiculos(datos.clasificacion!).then((response) => {
        datos.listatiposvehiculos = response!
      })

      return datos.listatiposvehiculos
    },
  })

  const listacilindradas = new DataSource({
    loadMode: 'raw',
    load: async () => {
      await getMotos(datos.clasificacion!, motos.idTipoVehiculo).then(
        (response) => {
          datos.listacilindradas = response
        },
      )

      return datos.listacilindradas
    },
  })

  const listacomunidadesautonomas = new DataSource({
    loadMode: 'raw',
    load: async () => {
      await getComunidadesAutonomas().then((response) => {
        datos.listacomunidadesautonomas = response!
      })

      return datos.listacomunidadesautonomas
    },
  })

  return (
    <GroupItem>
      <GroupItem colCount={1}>
        <SimpleItem
          label={{ text: 'Clasificación vehículo' }}
          editorType='dxSelectBox'
          editorOptions={{
            dataSource: clasificacionVehiculoMotocicleta,
            displayExpr: 'Name',
            valueExpr: 'Id',
            hint: 'ClasificacionVehiculo',
            value: datos.clasificacion,
            onValueChanged: (e: any) => {
              if (e.value !== undefined && e.value !== null) {
                motos.idTipoVehiculo = 0
                actualizardatos({
                  ...datos,
                  clasificacion: e.value,
                  listatiposvehiculos: [],
                  listacilindradas: [],
                })
              }
            },
          }}
        >
          <RequiredRule message='Campo obligatorio' />
        </SimpleItem>
      </GroupItem>
      <GroupItem colCount={2}>
        <SimpleItem
          label={{ text: 'Tipo de vehículo' }}
          editorType='dxSelectBox'
          editorOptions={{
            disabled:
              datos.clasificacion != undefined &&
              datos.clasificacion != null &&
              datos.clasificacion !== 0
                ? false
                : true,
            dataSource:
              datos.listatiposvehiculos.length > 0
                ? datos.listatiposvehiculos
                : listatiposvehiculos,
            displayExpr: 'descripcion',
            valueExpr: 'id',
            hint: 'Tipo De Vehículo',
            value:
              motos.idTipoVehiculo !== 0 && datos.listatiposvehiculos.length > 0
                ? datos.listatiposvehiculos.find(
                    (campo) => campo.id === motos.idTipoVehiculo,
                  )!.id
                : null,

            searchEnabled: true,
            searchMode: 'contains',
            searchExpr: ['descripcion'],

            onValueChanged: (e: any) => {
              if (e.value !== null) {
                actualizardatos({
                  ...datos,
                  idTipoVehiculo: e.value,
                  listacilindradas: [],
                })
                dispatch(
                  AddConsultaITPMotos({
                    ...motos,
                    idTipoVehiculo: e.value,
                    vehiculo: new MotosModel(),
                  }),
                )
              }
            },
          }}
        >
          {/* <Lookup
            disabled={
              datos.clasificacion != undefined &&
              datos.clasificacion != null &&
              datos.clasificacion !== 0
                ? false
                : true
            }
            dataSource={listatiposvehiculos}
            displayExpr={'descripcion'}
            key={'id'}
            searchEnabled={true}
            searchMode='contains'
            searchExpr={['descripcion']}
            onValueChange={(e: any) => {
              dispatch(
                AddConsultaITPMotos({
                  ...motos,
                  idTipoVehiculo: e.id,
                  vehiculo: new MotosModel(),
                }),
              )
            }}
            value={
              motos.idTipoVehiculo !== 0
                ? listatiposvehiculos.find(
                    (campo) => campo.id === motos.idTipoVehiculo,
                  )
                : null
            }
          >
            <Validator validationGroup='customerData'>
              <RequiredRule message='Campo obligatorio' />
            </Validator>
            <DropDownOptions position={'top'} height={320} />
          </Lookup> */}
          <RequiredRule message='Campo obligatorio'></RequiredRule>
        </SimpleItem>
        <SimpleItem
          label={{ text: 'Fecha primera matriculación' }}
          editorType='dxDateBox'
          editorOptions={{
            hint: 'Fecha Primera Matriculación',
            value: motos.fechaPrimeraMatriculacion,

            onValueChanged: (e: any) => {
              if (e.value !== undefined && e.value !== null && e.value !== '') {
                motos.fechaPrimeraMatriculacion = e.value
                dispatch(
                  AddConsultaITPMotos({
                    ...motos,
                    fechaPrimeraMatriculacion: formatoFecha(
                      formatoParaFecha(e.value),
                    ),
                  }),
                )
              }
            },
            showClearButton: true,
            useMaskBehavior: true,
            type: 'date',
            pickerType: 'calendar',
            displayFormat: 'dd/MM/yyyy',
          }}
        >
          <AsyncRule
            message='La fecha de matriculación no puede ser superior a la fecha de venta'
            validationCallback={(e: any) => {
              return esFechaMatriculacionSuperiorVenta(
                new Date(motos.fechaPrimeraMatriculacion),
                new Date(motos.fechaVenta),
              )
            }}
          />
          <RequiredRule message='Campo obligatorio' />
        </SimpleItem>
      </GroupItem>
      <GroupItem colCount={1}>
        <SimpleItem
          label={{ text: 'Cilindrada' }}
          editorType='dxSelectBox'
          editorOptions={{
            disabled:
              datos.listatiposvehiculos !== undefined &&
              datos.listatiposvehiculos !== null &&
              datos.listatiposvehiculos.find(
                (campo) => campo.id === motos.idTipoVehiculo,
              )
                ? false
                : true,
            dataSource:
              datos.listacilindradas.length > 0
                ? datos.listacilindradas
                : listacilindradas,
            displayExpr: 'descripcion',
            valueExpr: 'id',
            hint: 'Cilindrada',
            value:
              motos.vehiculo.id !== 0 && datos.listacilindradas.length > 0
                ? datos.listacilindradas.find(
                    (campo) => campo.id === motos.vehiculo.id,
                  )!.id
                : null,
            searchEnabled: true,
            searchMode: 'contains',
            searchExpr: ['descripcion'],

            onValueChanged: (e: any) => {
              if (e.value !== null) {
                let cilindrada = datos.listacilindradas.find(
                  (cilindro: MotosModel) => {
                    if (cilindro.id === e.value) {
                      return cilindro
                    }
                  },
                )

                dispatch(
                  AddConsultaITPMotos({ ...motos, vehiculo: cilindrada! }),
                )
              }
            },
          }}
        >
          {/* <Lookup
            disabled={
              listatiposvehiculos !== undefined &&
              listatiposvehiculos !== null &&
              listatiposvehiculos.find(
                (campo) => campo.id === motos.idTipoVehiculo,
              )
                ? false
                : true
            }
            dataSource={cilindradas}
            displayExpr={'descripcion'}
            key={'id'}
            searchEnabled={true}
            searchMode='contains'
            searchExpr={['descripcion']}
            onValueChange={(e: any) => {
              let cilindrada = listacilindradas.find((cilindro: MotosModel) => {
                if (cilindro.id === e.id) {
                  return cilindro
                }
              })

              dispatch(AddConsultaITPMotos({ ...motos, vehiculo: cilindrada! }))
            }}
            value={motos.vehiculo.id !== 0 ? motos.vehiculo.id : null}
          >
            <Validator validationGroup='customerData'>
              <RequiredRule message='Campo obligatorio' />
            </Validator>
            <DropDownOptions position={'top'} height={320} />
          </Lookup> */}
          <RequiredRule message='Campo obligatorio'></RequiredRule>
        </SimpleItem>
      </GroupItem>
      <GroupItem colCount={2}>
        <SimpleItem
          label={{ text: 'Fecha venta' }}
          editorType='dxDateBox'
          editorOptions={{
            hint: 'Fecha Venta',
            value: motos.fechaVenta,

            onValueChanged: (e: any) => {
              if (e.value !== undefined && e.value !== null && e.value !== '') {
                motos.fechaVenta = e.value
                dispatch(
                  AddConsultaITPMotos({
                    ...motos,
                    fechaVenta: formatoFecha(formatoParaFecha(e.value)),
                  }),
                )
              }
            },
            showClearButton: true,
            useMaskBehavior: true,
            type: 'date',
            min: formatoParaFecha(motos.fechaPrimeraMatriculacion),
            pickerType: 'calendar',
            displayFormat: 'dd/MM/yyyy',
          }}
        >
          <RequiredRule message='Campo obligatorio'></RequiredRule>
        </SimpleItem>

        <SimpleItem
          label={{ text: 'Comunidad autónoma' }}
          editorType='dxSelectBox'
          editorOptions={{
            disabled:
              datos.listatiposvehiculos !== undefined &&
              datos.listatiposvehiculos !== null &&
              datos.listatiposvehiculos.find(
                (campo) => campo.id === motos.idTipoVehiculo,
              )
                ? false
                : true,
            dataSource:
              datos.listacomunidadesautonomas.length > 0
                ? datos.listacomunidadesautonomas
                : listacomunidadesautonomas,
            displayExpr: 'nombre',
            valueExpr: 'id',
            hint: 'Comunidad Autonoma',
            value:
              motos.idComunidad !== 0 &&
              datos.listacomunidadesautonomas.length > 0
                ? datos.listacomunidadesautonomas.find(
                    (campo) => campo.id === motos.idComunidad,
                  )!.id
                : null,

            searchEnabled: true,
            searchMode: 'startswith',
            searchExpr: ['nombre'],

            onValueChanged: (e: any) => {
              if (e.event && e.value !== null) {
                motos.actividadesEconomicas = false
                motos.distintivo0Emisiones = false
                motos.distintivoEco = false
                dispatch(
                  AddConsultaITPMotos({
                    ...motos,
                    idComunidad: e.value,
                  }),
                )
              }
            },
          }}
        >
          <RequiredRule message='Campo obligatorio'></RequiredRule>
        </SimpleItem>
      </GroupItem>

      <GroupItem colCount={1}>
        <SimpleItem
          label={{
            text: 'Es taxi, vehículo de autoescuela o vehículo de alquiler con o sin conductor',
          }}
          editorType={'dxCheckBox'}
          editorOptions={{
            value: motos.esTaxiAutoescuelaAlquiler,
            onValueChanged: (e: any) => {
              dispatch(
                AddConsultaITPMotos({
                  ...motos,
                  esTaxiAutoescuelaAlquiler: e.value,
                }),
              )
            },
          }}
        ></SimpleItem>
      </GroupItem>

      <GroupItem colCount={3}>
        <SimpleItem
          label={{ text: 'Tiene actividades económicas' }}
          editorType={'dxCheckBox'}
          editorOptions={{
            disabled: datos.actividadeseconomicas ? false : true,
            value: motos.actividadesEconomicas,
            onValueChanged: (e: any) => {
              if (e.event && e.value !== null) {
                dispatch(
                  AddConsultaITPMotos({
                    ...motos,
                    actividadesEconomicas: e.value,
                  }),
                )
              }
            },
          }}
        ></SimpleItem>

        <SimpleItem
          label={{ text: 'Tiene distintivo de cero emisiones' }}
          editorType={'dxCheckBox'}
          editorOptions={{
            disabled: datos.distintivocero ? false : true,
            value: motos.distintivo0Emisiones,
            onValueChanged: (e: any) => {
              if (e.event && e.value !== null) {
                dispatch(
                  AddConsultaITPMotos({
                    ...motos,
                    distintivo0Emisiones: e.value,
                  }),
                )
              }
            },
          }}
        ></SimpleItem>

        <SimpleItem
          label={{ text: 'Tiene distintivo eco' }}
          editorType={'dxCheckBox'}
          editorOptions={{
            disabled: datos.distintivoeco ? false : true,
            value: motos.distintivoEco,
            onValueChanged: (e: any) => {
              if (e.event && e.value !== null) {
                dispatch(
                  AddConsultaITPMotos({
                    ...motos,
                    distintivoEco: e.value,
                  }),
                )
              }
            },
          }}
        ></SimpleItem>
      </GroupItem>

      <GroupItem colCount={1}>
        {footerFormularioSection({
          consulta: consulta,
          diferenciafechamatriculacionventa:
            motos.fechaPrimeraMatriculacion <= motos.fechaVenta,
        })}
      </GroupItem>
    </GroupItem>
  )
}
