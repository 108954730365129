import { GroupItem, RequiredRule, SimpleItem } from 'devextreme-react/form'
import React, { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { ConsultaITPTurismoModel } from 'src/models/ConsultaITPTurismoModel'
import { ModeloModel } from 'src/models/modeloModel'
import { AddConsultaITPTurismo } from 'src/redux/CalculoRedux/actions/calculoActions'
import DataSource from 'devextreme/data/data_source'
import {
  esFechaMatriculacionSuperiorVenta,
  formatoFecha,
  formatoParaFecha,
} from 'src/utils/calculadoraUtils'
import { clasificacionVehiculosTurismo } from 'src/models/clasificacionesEnum'
import footerFormularioSection from '../FooterFormularioSection/footerFormularioSection'
import { Lookup, SelectBox, Validator } from 'devextreme-react'
import { DropDownOptions } from 'devextreme-react/lookup'
import { AsyncRule } from 'devextreme-react/data-grid'
import {
  getCombustible,
  getComunidadesAutonomas,
  getMarcas,
  getModelos,
  getTipoVehiculos,
} from 'src/services/calculadoraServices'
import { TipoVehiculoModel } from 'src/models/tipoVehiculoModels'
import { MarcasModel } from 'src/models/marcasModel'
import { CombustibleModel } from 'src/models/combustibleModel'
import { ComunidadAutonomaModel } from 'src/models/comunidadAutonomaModel'

interface Props {
  turismo: ConsultaITPTurismoModel
  datos: {
    marca: number
    combustible: string
    modelo: number
    clasificacion: number | null
    actividadeseconomicas: boolean
    distintivocero: boolean
    distintivoeco: boolean
    listatiposvehiculos: TipoVehiculoModel[]
    listacomunidadesautonomas: ComunidadAutonomaModel[]
    listamarcas: MarcasModel[]
    listacombustible: CombustibleModel[]
    listamodelos: ModeloModel[]
  }
  actualizardatos: Function

  consulta: Function
}
export default function FormularioTurismoSection({
  turismo,
  datos,
  actualizardatos,
  consulta,
}: Props): ReactElement {
  const dispatch = useDispatch()
  const listatiposvehiculos = new DataSource({
    loadMode: 'raw',
    load: async () => {
      await getTipoVehiculos(1).then((response) => {
        datos.listatiposvehiculos = response!
      })

      return datos.listatiposvehiculos
    },
  })

  const listacomunidadesautonomas = new DataSource({
    loadMode: 'raw',
    load: async () => {
      await getComunidadesAutonomas().then((response) => {
        datos.listacomunidadesautonomas = response!
      })

      return datos.listacomunidadesautonomas
    },
  })

  const listamarcas = new DataSource({
    loadMode: 'raw',
    load: async () => {
      await getMarcas(
        datos.clasificacion === 1
          ? turismo.idTipoVehiculo!
          : turismo.idTipoVehiculo!,
        formatoFecha(
          formatoParaFecha(
            datos.clasificacion === 1
              ? turismo.fechaPrimeraMatriculacion!
              : turismo.fechaPrimeraMatriculacion!,
          ),
        ),
      ).then((response) => {
        datos.listamarcas = response!
      })
      return datos.listamarcas
    },
  })

  const listacombustible = new DataSource({
    loadMode: 'raw',
    load: async () => {
      await getCombustible(
        datos.clasificacion === 1
          ? turismo.idTipoVehiculo!
          : turismo.idTipoVehiculo!,
        formatoFecha(
          formatoParaFecha(
            datos.clasificacion === 1
              ? turismo.fechaPrimeraMatriculacion!
              : turismo.fechaPrimeraMatriculacion!,
          ),
        ),
        datos.marca!,
      ).then((response) => {
        datos.listacombustible = response!
      })

      return datos.listacombustible
    },
  })

  const listamodelos = new DataSource({
    loadMode: 'raw',
    load: async () => {
      await getModelos(
        datos.combustible,
        datos.clasificacion === 1
          ? turismo.idTipoVehiculo!
          : turismo.idTipoVehiculo!,
        formatoFecha(
          formatoParaFecha(
            datos.clasificacion === 1
              ? turismo.fechaPrimeraMatriculacion!
              : turismo.fechaPrimeraMatriculacion!,
          ),
        ),
        datos.marca!,
      ).then((response) => {
        datos.listamodelos = response!
      })

      return datos.listamodelos
    },
  })

  return (
    <GroupItem>
      <GroupItem colCount={1}>
        <SimpleItem
          label={{ text: 'Clasificación vehículo' }}
          editorType='dxSelectBox'
          editorOptions={{
            dataSource: clasificacionVehiculosTurismo,
            displayExpr: 'Name',
            valueExpr: 'Id',
            hint: 'ClasificacionVehiculo',
            value: 1,
          }}
        >
          <RequiredRule message='Campo obligatorio'></RequiredRule>
        </SimpleItem>
      </GroupItem>
      <GroupItem colCount={2}>
        <SimpleItem
          label={{ text: 'Tipo de vehículo' }}
          editorType='dxSelectBox'
          editorOptions={{
            dataSource:
              datos.listatiposvehiculos.length > 0
                ? datos.listatiposvehiculos
                : listatiposvehiculos,
            displayExpr: 'descripcion',
            valueExpr: 'id',
            hint: 'Tipo De Vehiculo',
            value:
              turismo.idTipoVehiculo !== 0 &&
              datos.listatiposvehiculos.length > 0
                ? datos.listatiposvehiculos.find(
                    (campo) => campo.id === turismo.idTipoVehiculo,
                  )!.id
                : null,
            searchEnabled: true,
            searchMode: 'contains',
            searchExpr: ['descripcion'],

            onValueChanged: (e: any) => {
              if (e.value !== null) {
                turismo.idTipoVehiculo = e.value
                actualizardatos({
                  ...datos,
                  marca: 0,
                  combustible: '',
                  modelo: 0,
                  clasificacion: 1,
                  listamarcas: [],
                })
              }
            },
          }}
        >
          <RequiredRule message='Campo obligatorio'></RequiredRule>
        </SimpleItem>
        <SimpleItem
          label={{ text: 'Fecha primera matriculación' }}
          editorType='dxDateBox'
          editorOptions={{
            hint: 'Fecha Primera Matriculación',
            value: turismo.fechaPrimeraMatriculacion,
            onValueChanged: (e: any) => {
              if (e.value !== undefined && e.value !== null && e.value !== '') {
                turismo.fechaPrimeraMatriculacion = formatoFecha(
                  formatoParaFecha(e.value),
                )
                actualizardatos({
                  ...datos,
                  marca: 0,
                  combustible: '',
                  modelo: 0,
                  listamarcas: [],
                })
              }
            },
            showClearButton: true,
            useMaskBehavior: true,
            type: 'date',
            pickerType: 'calendar',
            displayFormat: 'dd/MM/yyyy',
          }}
        >
          <AsyncRule
            message='La fecha de matriculación no puede ser superior a la fecha de venta'
            validationCallback={(e: any) => {
              return esFechaMatriculacionSuperiorVenta(
                new Date(turismo.fechaPrimeraMatriculacion),
                new Date(turismo.fechaVenta),
              )
            }}
          />

          <RequiredRule message='Campo obligatorio'></RequiredRule>
        </SimpleItem>
      </GroupItem>
      <GroupItem colCount={2}>
        <SimpleItem
          label={{ text: 'Marca' }}
          editorType='dxSelectBox'
          editorOptions={{
            disabled:
              datos.listatiposvehiculos !== undefined &&
              datos.listatiposvehiculos !== null &&
              datos.listatiposvehiculos.find(
                (campo) => campo.id === turismo.idTipoVehiculo,
              ) !== undefined &&
              turismo.fechaPrimeraMatriculacion !== ''
                ? false
                : true,
            dataSource:
              datos.listamarcas.length > 0 ? datos.listamarcas : listamarcas,
            displayExpr: 'nombre',
            valueExpr: 'id',
            hint: 'Marca',
            value:
              datos.marca !== 0 && datos.listamarcas.length > 0
                ? datos.listamarcas.find((campo) => campo.id === datos.marca)!
                    .id
                : null,
            searchEnabled: true,
            searchMode: 'contains',
            searchExpr: ['nombre'],

            onValueChanged: (e: any) => {
              if (e.value !== null) {
                actualizardatos({
                  ...datos,
                  marca: e.value,
                  combustible: '',
                  modelo: 0,
                  listacombustible: [],
                })
              }
            },
          }}
        >
          <RequiredRule message='Campo obligatorio'></RequiredRule>
        </SimpleItem>
        <SimpleItem
          label={{ text: 'Combustible' }}
          editorType='dxSelectBox'
          editorOptions={{
            disabled:
              datos.listamarcas !== undefined &&
              datos.listamarcas !== null &&
              datos.listamarcas.find((campo) => campo.id === datos.marca) !==
                undefined
                ? false
                : true,
            dataSource:
              datos.listacombustible.length > 0
                ? datos.listacombustible
                : listacombustible,
            displayExpr: 'descripcion',
            valueExpr: 'siglas',
            hint: 'Combustible',
            value:
              datos.combustible !== '' && datos.listacombustible.length > 0
                ? datos.listacombustible.find(
                    (campo) => campo.siglas === datos.combustible,
                  )!.siglas
                : null,

            searchEnabled: true,
            searchMode: 'contains',
            searchExpr: ['descripcion'],

            onValueChanged: (e: any) => {
              if (e.value !== null) {
                actualizardatos({
                  ...datos,
                  combustible: e.value,
                  modelo: 0,
                  listamodelos: [],
                })
              }
            },
          }}
        >
          <RequiredRule message='Campo obligatorio' />
        </SimpleItem>
      </GroupItem>
      <GroupItem colCount={1}>
        <SimpleItem
          label={{ text: 'Modelo' }}
          editorType='dxSelectBox'
          editorOptions={{
            disabled:
              datos.listacombustible !== undefined &&
              datos.listacombustible !== null &&
              datos.listacombustible.find(
                (campo) => campo.siglas === datos.combustible,
              ) !== undefined
                ? false
                : true,
            dataSource:
              datos.listamodelos.length > 0 ? datos.listamodelos : listamodelos,
            displayExpr: (item: ModeloModel) => {
              return (
                item &&
                item.marca.replace(/\s+/g, ' ') +
                  ' ' +
                  item.modelo.replace(/\s+/g, ' ')
              )
            },
            valueExpr: 'id',
            hint: 'Modelo',
            value:
              datos.combustible !== '' && datos.listamodelos.length > 0
                ? datos.listamodelos.find((campo) => campo.id === datos.modelo)!
                    .id
                : null,
            searchEnabled: true,
            searchMode: 'contains',
            searchExpr: ['marca', 'modelo'],

            onValueChanged: (e: any) => {
              if (e.value !== null) {
                let modelodatos = datos.listamodelos.find((modelo) => {
                  if (modelo.id === e.value) {
                    return modelo
                  }
                })
                turismo.vehiculo = modelodatos!
                actualizardatos({ ...datos, modelo: e.value })
              }
            },
          }}
        >
          <RequiredRule message='Campo obligatorio'></RequiredRule>
        </SimpleItem>
      </GroupItem>
      <GroupItem colCount={2}>
        <SimpleItem
          label={{ text: 'Fecha venta' }}
          editorType='dxDateBox'
          editorOptions={{
            hint: 'Fecha Venta',
            value: turismo.fechaVenta,

            onValueChanged: (e: any) => {
              if (e.value !== undefined && e.value !== null && e.value !== '') {
                dispatch(
                  AddConsultaITPTurismo({
                    ...turismo,
                    fechaVenta: formatoFecha(formatoParaFecha(e.value)),
                  }),
                )
              }
            },
            showClearButton: true,
            useMaskBehavior: true,
            type: 'date',
            min: formatoParaFecha(turismo.fechaPrimeraMatriculacion),
            pickerType: 'calendar',
            displayFormat: 'dd/MM/yyyy',
          }}
        >
          <RequiredRule message='Campo obligatorio'></RequiredRule>
        </SimpleItem>
        <SimpleItem
          label={{ text: 'Comunidad autónoma' }}
          editorType='dxSelectBox'
          editorOptions={{
            disabled:
              datos.listatiposvehiculos !== undefined &&
              datos.listatiposvehiculos !== null &&
              datos.listatiposvehiculos.find(
                (campo) => campo.id === turismo.idTipoVehiculo,
              ) !== undefined
                ? false
                : true,
            dataSource:
              datos.listacomunidadesautonomas.length > 0
                ? datos.listacomunidadesautonomas
                : listacomunidadesautonomas,
            displayExpr: 'nombre',
            valueExpr: 'id',
            hint: 'Comunidad Autónoma',
            value:
              turismo.idComunidad !== 0 &&
              datos.listacomunidadesautonomas.length > 0
                ? datos.listacomunidadesautonomas.find(
                    (campo) => campo.id === turismo.idComunidad,
                  )!.id
                : null,
            searchEnabled: true,
            searchMode: 'startswith',
            searchExpr: ['nombre'],

            onValueChanged: (e: any) => {
              if (e.event && e.value !== null) {
                turismo.actividadesEconomicas = false
                turismo.distintivo0Emisiones = false
                turismo.distintivoEco = false

                dispatch(
                  AddConsultaITPTurismo({
                    ...turismo,
                    idComunidad: e.value,
                  }),
                )
              }
            },
          }}
        >
          <RequiredRule message='Campo obligatorio'></RequiredRule>
        </SimpleItem>
      </GroupItem>

      <GroupItem colCount={1}>
        <SimpleItem
          label={{
            text: 'Es taxi, vehículo de autoescuela o vehículo de alquiler con o sin conductor',
          }}
          editorType={'dxCheckBox'}
          editorOptions={{
            value: turismo.esTaxiAutoescuelaAlquiler,
            onValueChanged: (e: any) => {
              if (e.event && e.value !== null) {
                dispatch(
                  AddConsultaITPTurismo({
                    ...turismo,
                    esTaxiAutoescuelaAlquiler: e.value,
                  }),
                )
              }
            },
          }}
        ></SimpleItem>
      </GroupItem>

      <GroupItem colCount={3}>
        <SimpleItem
          label={{ text: 'Tiene actividades económicas' }}
          editorType={'dxCheckBox'}
          editorOptions={{
            disabled: datos.actividadeseconomicas ? false : true,
            value: turismo.actividadesEconomicas,
            onValueChanged: (e: any) => {
              if (e.event && e.value !== null) {
                dispatch(
                  AddConsultaITPTurismo({
                    ...turismo,
                    actividadesEconomicas: e.value,
                  }),
                )
              }
            },
          }}
        ></SimpleItem>

        <SimpleItem
          label={{ text: 'Tiene distintivo de cero emisiones' }}
          editorType={'dxCheckBox'}
          editorOptions={{
            disabled: datos.distintivocero ? false : true,
            value: turismo.distintivo0Emisiones,
            onValueChanged: (e: any) => {
              if (e.event && e.value !== null) {
                dispatch(
                  AddConsultaITPTurismo({
                    ...turismo,
                    distintivo0Emisiones: e.value,
                  }),
                )
              }
            },
          }}
        ></SimpleItem>

        <SimpleItem
          label={{ text: 'Tiene distintivo eco' }}
          editorType={'dxCheckBox'}
          editorOptions={{
            disabled: datos.distintivoeco ? false : true,
            value: turismo.distintivoEco,
            onValueChanged: (e: any) => {
              if (e.event && e.value !== null) {
                dispatch(
                  AddConsultaITPTurismo({
                    ...turismo,
                    distintivoEco: e.value,
                  }),
                )
              }
            },
          }}
        ></SimpleItem>
      </GroupItem>

      <GroupItem colCount={1}>
        {footerFormularioSection({
          consulta: consulta,
          diferenciafechamatriculacionventa:
            turismo.fechaPrimeraMatriculacion <= turismo.fechaVenta,
        })}
      </GroupItem>
    </GroupItem>
  )
}
