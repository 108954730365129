import { UserModel } from 'src/models/userModel'
import {
  ADD_USER,
  UserState,
  UsersActionsTypes,
} from '../types/usersActionsTypes'

const userState: UserState = {
  usuario: new UserModel(),
}

export const UserReducer = (
  state = userState,
  action: UsersActionsTypes,
): UserState => {
  switch (action.type) {
    case ADD_USER: {
      return { ...state, usuario: action.payload }
    }

    default:
      return state
  }
}
