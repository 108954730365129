import { ConsultaITPMotosModel } from 'src/models/ConsultaITPMotosModel'
import { ConsultaITPTurismoModel } from 'src/models/ConsultaITPTurismoModel'

export const ADD_CALCULADORA_CONSULTA_ITPTURISMO =
  'ADD_CALCULADORA_CONSULTA_ITPTURISMO'
export const ADD_CALCULADORA_CONSULTA_ITPMOTOS =
  'ADD_CALCULADORA_CONSULTA_ITPMOTOS'

export interface calculoState {
  ITPTurismo: ConsultaITPTurismoModel
  ITPMotos: ConsultaITPMotosModel
}

interface AddConsultaITPTurismo {
  type: typeof ADD_CALCULADORA_CONSULTA_ITPTURISMO
  payload: ConsultaITPTurismoModel
}

interface AddConsultaITPMotos {
  type: typeof ADD_CALCULADORA_CONSULTA_ITPMOTOS
  payload: ConsultaITPMotosModel
}

export type CalculoTypesActions = AddConsultaITPTurismo | AddConsultaITPMotos
