import notify from 'devextreme/ui/notify'
import { UserModel } from 'src/models/userModel'

export enum NotifyType {
  info = 'info',
  success = 'success',
  warning = 'warning',
  error = 'error',
}

export const showToast = (
  text: string,
  type: NotifyType,
  timer?: number,
): any => {
  return notify(text, type, timer ?? 1200)
}

export const authHeader = (user: UserModel) => {
  return {
    Authorization: 'Bearer ' + user.Token,
  }
}
