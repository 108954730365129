import Toolbar, { Item } from 'devextreme-react/toolbar'
import { useSelector } from 'react-redux'
import React from 'react'
import { RootState } from 'src/redux/combineReducers'
import Button from 'devextreme-react/button'
import UserPanelSection from './sections/userpanel/userPanelSection'
import { Template } from 'devextreme-react/core/template'

export default function Header() {
  const userState = useSelector((state: RootState) => state.user.usuario)
  return (
    <Toolbar width={'100%'} height={55} className='headerstyle'>
      <Item location={'before'}>
        <img
          src={require('../../imgs/newlogo.png')}
          width={200}
          height={'auto'}
          alt=''
        />
      </Item>

      {userState.UserName !== '' && userState.Token !== '' && (
        <Item
          location='after'
          locateInMenu='auto'
          menuItemTemplate='userPanelTemplate'
        >
          <Button id='userpanel' height='100%'>
            <UserPanelSection menuMode={'context'} />
          </Button>
        </Item>
      )}
      <Item location={'after'} visible={window.innerWidth > 500 ? true : false}>
        <br></br>
      </Item>
      <Template name='userPanelTemplate'>
        <UserPanelSection menuMode='list' />
      </Template>
    </Toolbar>
  )
}
