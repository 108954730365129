import { JsonObject, JsonProperty } from 'json2typescript'
import { MotosModel } from './motosModel'
import { formatoFecha, formatoParaFecha } from 'src/utils/calculadoraUtils'

@JsonObject('ConsultaITPMotosModel')
export class ConsultaITPMotosModel {
  @JsonProperty('vehiculo', MotosModel)
  vehiculo: MotosModel = new MotosModel()
  @JsonProperty('idComunidad', Number)
  idComunidad: number = 0
  @JsonProperty('fechaPrimeraMatriculacion', String)
  fechaPrimeraMatriculacion: string = ''
  @JsonProperty('fechaVenta', String)
  fechaVenta: string = formatoFecha(formatoParaFecha(new Date().toString()))
  @JsonProperty('idTipoVehiculo', Number)
  idTipoVehiculo: number = 0
  @JsonProperty('actividadesEconomicas', Boolean)
  actividadesEconomicas: boolean | null = null
  @JsonProperty('distintivo0Emisiones', Boolean)
  distintivo0Emisiones: boolean | null = null
  @JsonProperty(' distintivoEco', Boolean)
  distintivoEco: boolean | null = null
  @JsonProperty('esTaxiAutoescuelaAlquiler', Boolean)
  esTaxiAutoescuelaAlquiler: boolean = false
}
