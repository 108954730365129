import { UserModel } from 'src/models/userModel'

export const ADD_USER = 'ADD_USER'

export interface UserState {
  usuario: UserModel
}

interface addUser {
  type: typeof ADD_USER
  payload: UserModel
}

export type UsersActionsTypes = addUser
