import { Box, Button } from 'devextreme-react'
import { Item } from 'devextreme-react/box'
import React from 'react'

interface Props {
  consulta: Function
  diferenciafechamatriculacionventa: Boolean
}

export default function footerFormularioSection({
  consulta,
  diferenciafechamatriculacionventa,
}: Props) {
  return (
    <Box direction='row' height={60} align='end' crossAlign='end'>
      <Item ratio={0} baseSize='auto'>
        <Button
          text={'Calcular'}
          type='default'
          validationGroup='customerData'
          onClick={async (e: any) => {
            e.event.preventDefault()
            let result = e.validationGroup.validate()
            if (result.isValid && diferenciafechamatriculacionventa) {
              consulta()
            }
          }}
        />
      </Item>
    </Box>
  )
}
