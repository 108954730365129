import {
  ConfigActionsTypes,
  LOGIN_BY_TOKEN,
  SHOW_LOADER,
} from '../types/configActionsTypes'

export const addShowLoader = (visible: boolean): ConfigActionsTypes => ({
  type: SHOW_LOADER,
  payload: visible,
})

export const AddLoginByToken = (loginbytoken: boolean): ConfigActionsTypes => ({
  type: LOGIN_BY_TOKEN,
  payload: loginbytoken,
})
