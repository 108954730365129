export class ParamsUrlModel {
  key: string = ''
  theme: string = ''
  idgestoria: string = ''
  vienedelportal: string = 'false'

  constructor(
    key?: string | null,
    theme?: string | null,
    idgestoria?: string | null,
    vienedelportal?: string | null,
  ) {
    this.key = key !== undefined && key !== null ? key : ''
    this.theme = theme !== undefined && theme !== null ? theme : ''
    this.idgestoria =
      idgestoria !== undefined && idgestoria !== null ? idgestoria : ''
    this.vienedelportal =
      vienedelportal !== undefined && vienedelportal !== null
        ? vienedelportal
        : 'false'
  }
}
