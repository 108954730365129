import { JsonObject, JsonProperty } from 'json2typescript'
@JsonObject('MotosModel')
export class MotosModel {
  @JsonProperty('id', Number)
  id: number = 0
  @JsonProperty('idTipoVehiculo', Number)
  idTipoVehiculo: number = 0
  @JsonProperty('descripcion', String)
  descripcion: string = ''
  @JsonProperty('cilindradaMinima', Number)
  cilindradaMinima: number = 0
  @JsonProperty('cilindradaMaxima', Number)
  cilindradaMaxima: number = 0
  @JsonProperty('kwMinimo', Number)
  kwMinimo: number = 0
  @JsonProperty('kwMaximo', Number)
  kwMaximo: number = 0
  @JsonProperty('cvMinimo', Number)
  cvMinimo: number = 0
  @JsonProperty('cvMaximo', Number)
  cvMaximo: number = 0
  @JsonProperty('valor', Number)
  valor: number = 0
  @JsonProperty('importeTasaDgt', Number)
  importeTasaDgt: number = 0
}
