import { Box, ContextMenu } from 'devextreme-react'
import { Item } from 'devextreme-react/box'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ConsultaITPMotosModel } from 'src/models/ConsultaITPMotosModel'
import { ConsultaITPTurismoModel } from 'src/models/ConsultaITPTurismoModel'
import { UserModel } from 'src/models/userModel'
import {
  AddConsultaITPMotos,
  AddConsultaITPTurismo,
} from 'src/redux/CalculoRedux/actions/calculoActions'
import { RootState } from 'src/redux/combineReducers'
import { addUser } from 'src/redux/users/actions/userAction'
import { getTimeLogIn } from 'src/utils/calculadoraUtils'
import List from 'devextreme-react/list'
import { AddLoginByToken } from 'src/redux/configRedux/actions/configActions'
interface Props {
  menuMode: any
}
export default function UserPanelSection({ menuMode }: Props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userState = useSelector((state: RootState) => state.user.usuario)
  const menuItems = [
    {
      text: 'Ayuda',
      icon: 'help',
      onClick: () =>
        window.open(
          'https://wiki.ruano.com/index.php?title=Portal:_Índice',
          '_blank',
        ),
    },
    {
      text: getTimeLogIn(),
      icon: 'clock',
      style: { fontWeight: 'bold' },
    },
    {
      text: 'Cerrar Sesión',
      icon: 'runner',
      onClick: () => {
        CloseSession()
        navigate('/')
      },
    },
  ]

  function CloseSession() {
    dispatch(AddConsultaITPTurismo(new ConsultaITPTurismoModel()))
    dispatch(AddConsultaITPMotos(new ConsultaITPMotosModel()))
    dispatch(addUser(new UserModel()))
    dispatch(AddLoginByToken(false))
    localStorage.removeItem('user')
  }

  return (
    <>
      <Box direction='row' width={100} align='start' crossAlign='start'>
        <Item ratio={0} baseSize={90}>
          <p>{userState.UserName}</p>
        </Item>
        <Item ratio={0} baseSize={50}>
          <img
            src={require('../../../../imgs/user.png')}
            width={23}
            height={23}
            style={{ position: 'relative', top: 8 }}
          />
        </Item>
      </Box>

      {menuMode === 'context' && (
        <ContextMenu
          items={menuItems}
          target={'#userpanel'}
          showEvent={'dxcontextmenu dxclick'}
          position={{
            my: { x: 'right', y: 'bottom' },
            at: { x: 'right', y: 'top' },
          }}
        />
      )}

      {menuMode === 'list' && <List items={menuItems} />}
    </>
  )
}
